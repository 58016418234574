import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Id, toast } from 'react-toastify';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import useQuery from '../../hooks/useQuery';
import { DefaultURLQuery, EventType } from '../../types/misc';
import ShoppingCart from '../misc/ShoppingCart';
import { validateUserDetails } from '../../utils/validationUtils';
import Event from '../../utils/event';
import {
  CARDHOLDERS_ROUTE,
  NOT_FOUND_ROUTE,
  PAYMENT_ROUTE,
  SHOP_ROUTES,
  SUBSCRIPTIONS_ROUTE,
  USER_DETAILS_ROUTE,
} from '../../constants';
import { RootState } from '../../types/redux';
import ProgressComponent from '../misc/ProgressComponent';

export default function ShopLayout(): JSX.Element {
  const query = useQuery<DefaultURLQuery>();
  const location = useLocation();
  const { t } = useTranslation('translation', { keyPrefix: 'component.shopLayout' });
  const navigate = useNavigate();
  const {
    shoppingCart: { purchases },
    userDetails: { userDetails },
    cardholders: { cardholders },
  } = useSelector((state: RootState): RootState => state);

  const shoppingCartFilled = !!purchases.length;
  const userDetailsValid = !Object.values(validateUserDetails(userDetails ?? {})).some(Boolean);
  const cardholdersValid = !purchases.some(({ purchaseId }): boolean =>
    cardholders[purchaseId]?.some((c): boolean => !c.firstName || !c.lastName || !c.birthdate),
  );
  const params = Object.entries(query)
    .map(([key, value]): string => `${key}=${value}`)
    .join('&');

  /**
   * buttonProps - props for the button in the shopping cart
   * allowNext - whether the user can proceed to the next page
   * allowedPage - whether user is allowed to be on current page
   */
  const [buttonProps, allowNext, allowedPage, title] = ((): [any, undefined | boolean, undefined | boolean, string] => {
    switch (location.pathname) {
      case SUBSCRIPTIONS_ROUTE:
        return [
          {
            children: t('continue'),
            onClick: (): false | Id => !shoppingCartFilled && toast.error(t('cartEmpty')),
          },
          shoppingCartFilled,
          true,
          t('titles.subscriptions'),
        ];
      case USER_DETAILS_ROUTE:
        return [
          { children: t('continue'), onClick: (): void => { Event.emit(EventType.SUBMIT_USER_DETAILS); } },
          userDetailsValid,
          shoppingCartFilled,
          t('titles.userDetails'),
        ];
      case CARDHOLDERS_ROUTE:
        return [
          { children: t('toPayment'), onClick: (): void => { Event.emit(EventType.SUBMIT_CARDHOLDERS); } },
          cardholdersValid,
          shoppingCartFilled && userDetailsValid,
          t('titles.cardholders'),
        ];
      case PAYMENT_ROUTE:
        return [
          undefined,
          false,
          userDetailsValid && shoppingCartFilled && cardholdersValid,
          t('titles.payment'),
        ];
      default:
        return [undefined, undefined, undefined, ''];
    }
  })();

  useEffect((): void => {
    if (SHOP_ROUTES.includes(location.pathname) && !allowedPage)
      navigate(
        `${links[location.pathname as keyof typeof links]?.prev ?? NOT_FOUND_ROUTE}?${params}`,
        { replace: true },
      );
  }, [allowedPage, location.pathname]);

  return query.sId && query.lId && [...SHOP_ROUTES, '/'].includes(location.pathname) ? (
    <>
      <div className="flex w-full lg:w-1/3 lg:pt-5 mx-auto ">
        <ProgressComponent />
      </div>
      <h1 className="text-[24px] leading-[24px] font-ginto-bold text-center hidden lg:block text-white">
        {title}
      </h1>
      <div className="flex flex-col gap-4 lg:flex-row">
        <Outlet />
        <ShoppingCart
          next={links[location.pathname as keyof typeof links]?.next}
          allowNext={allowNext}
          buttonProps={buttonProps}
        />
      </div>
      <footer className="bg-sb-dark-purple py-4 text-white text-center">
        <div className="flex justify-center gap-4 text-[14px]">
          <a
            href="https://www.schaatsbaanrotterdam.nl/general/Algemene-Voorwaarden-en-Bezoekersreglement-2024-2025.pdf"
            target="_blank"
            rel="noreferrer"
            className="font-medium text-slate-500 hover:text-white hover:underline"
          >
            {t("terms")}
          </a>
          <a
            href="https://www.schaatsbaanrotterdam.nl/privacy-verklaring/"
            target="_blank"
            rel="noreferrer"
            className="font-medium text-slate-500 hover:text-white hover:underline"
          >
            {t("privacy")}
          </a>
          <a
            href="https://www.schaatsbaanrotterdam.nl/veelgestelde-vragen/"
            target="_blank"
            rel="noreferrer"
            className="font-medium text-slate-500 hover:text-white hover:underline"
          >
            {t("faq")}
          </a>
        </div>
      </footer>
    </>
  ) : (
    <Navigate to={NOT_FOUND_ROUTE} />
  );
}

const links = {
  [SUBSCRIPTIONS_ROUTE]: { prev: undefined, next: USER_DETAILS_ROUTE },
  [USER_DETAILS_ROUTE]: { prev: SUBSCRIPTIONS_ROUTE, next: CARDHOLDERS_ROUTE },
  [CARDHOLDERS_ROUTE]: { prev: USER_DETAILS_ROUTE, next: PAYMENT_ROUTE },
  [PAYMENT_ROUTE]: { prev: CARDHOLDERS_ROUTE, next: undefined },
};
