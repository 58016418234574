import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { CSSObjectWithLabel } from 'react-select';
import { CgSpinnerAlt } from 'react-icons/cg';
import Button from '../../components/buttons/Button';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import MessageBubble from '../../components/misc/MessageBubble';
import { PaymentMethod, PaymentMethodId } from '../../types/payment';
import { paymentService } from '../../services';
import useShoppingCart from '../../hooks/selectors/useShoppingCart';
import ContentPanel from '../../components/layouts/ContentPanel';
import useCrashHandler from '../../hooks/useCrashHandler';
import DropdownInput from '../../components/inputs/DropdownInput';
import DiscountInput from '../../components/inputs/DiscountInput';
import DiscountList from '../../components/misc/DiscountList';
import DialogBox from '../../components/inputs/DialogBox';
import Event from '../../utils/event';
import { SB_LIGHT_PINK, SB_PINK } from '../../constants';
import { EventType } from '../../types/misc';

export default function PaymentPage(): JSX.Element {
  const {
    price: { totalPrice },
  } = useShoppingCart();
  const crashHandler = useCrashHandler();
  const { t } = useTranslation('translation', { keyPrefix: 'page.payment' });

  const [methods, setMethods] = useState<PaymentMethod[]>([]);
  const selectedMethodState = useState<PaymentMethodId | undefined>();
  const consentState = useState<boolean>(false);
  const agreement = useState<string[]>([]);

  useEffect((): void => {
    paymentService.getPaymentMethods().then(setMethods).catch(crashHandler);
  }, []);

  const { method, issuer } = selectedMethodState[0] || {};
  const canSubmit =
    method && (!methods.find((m): boolean => m.id === method)?.issuers || issuer) && consentState[0];
  const [loadingState, setLoadingState] = useState<boolean>(false);

  const { discounts } = useShoppingCart();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleDialogClose = (result: boolean): void => {
    setIsDialogOpen(false);
    if (result) {
      handlePayment();
    }
  };

  const handleSubmit = (): void => {
    if (loadingState) return;
    setLoadingState(true);

    if (!method || (methods.find((m): boolean => m.id === method)?.issuers?.length && !issuer)) {
      Event.emit(EventType.SUBMIT_PAYMENT);
      toast.warning(t(!method ? 'methodRequired' : 'issuerRequired'));
      setLoadingState(false);
      return;
    }
    if (!consentState[0]) {
      toast.warning(t('termsAndConditionsRequired'));
      setLoadingState(false);
      return;
    }
    if (discounts.filter((d): boolean => d.times === 0).length > 0) {
      setIsDialogOpen(true);
    } else {
      handlePayment();
    }
  };

  const handlePayment = (): void => {
    if (!canSubmit) return;

    paymentService
      .createPayment({ method, issuer }, agreement[0])
      .then((url): void => {
        window.location.href = url;
      })
      .catch(crashHandler)
      .finally((): void => { 
        setTimeout((): void => setLoadingState(false), 1000) 
      });
  };

  return (
    <ContentPanel>
      <PaymentOptions selectedMethodState={selectedMethodState} methods={methods} />
      <p className="text-[17px] leading-[22px]">{t('discount')}</p>
      <DiscountInput />
      <DiscountList />
      <ConsentCheckboxes consentState={consentState} agreement={agreement}/>
      <MessageBubble>{t('warningNoInfo')}</MessageBubble>
      <Button variant="pink" disabled={loadingState} className="w-full" onClick={handleSubmit}>
        { loadingState && <CgSpinnerAlt className="animate-spin mr-3" size={18} /> } {t('button')} (€{(totalPrice.discountedPrice ?? totalPrice.price)?.toFixed(2) || '-,-'})
      </Button>
      <DialogBox
        isOpen={isDialogOpen}
        onClose={handleDialogClose}
        titleText={t('dialogBox.title')}
        cancelText={t('dialogBox.cancel')}
        confirmText={t('dialogBox.confirm')}>
        <p>{t('discounts_not_applied_note')}</p>
        <ul className="list-disc ml-4 mt-4">
          {discounts
            .filter((d): boolean => d.times === 0)
            .map((d): JSX.Element => (
              <li key={d.code}>{d.code}</li>
            ))}
        </ul>
      </DialogBox>
    </ContentPanel>
  );
}

interface PaymentOptionsProps {
  methods: PaymentMethod[];
  selectedMethodState: [
    PaymentMethodId | undefined,
    React.Dispatch<React.SetStateAction<PaymentMethodId | undefined>>,
  ];
}

function PaymentOptions({ methods, selectedMethodState }: PaymentOptionsProps): JSX.Element {
  const [selectedMethod, setSelectedMethod] = selectedMethodState;
  const [submitted, setSubmitted] = useState(false);

  const setSubmittedTrue = (): void => setSubmitted(true);

  useEffect((): any => {
    Event.addListener(EventType.SUBMIT_PAYMENT, setSubmittedTrue);

    return (): void => {
      Event.removeListener(EventType.SUBMIT_PAYMENT, setSubmittedTrue);
    };
  }, []);

  const { method, issuer } = selectedMethod || {};

  const { t } = useTranslation('translation', { keyPrefix: 'page.payment' });

  const handleMethodSelect = (id: string): void => {
    if (method === id) setSelectedMethod(undefined);
    else {
      setSelectedMethod({ method: id });
      setSubmitted(false);
    }
  };

  const handleIssuerSelect = (id: string | undefined): void => {
    if (!method || issuer === id) return;
    setSelectedMethod({ method, issuer: id });
    setSubmitted(false);
  };

  const issuers = methods.find((m): boolean => m.id === method)?.issuers;

  return (
    <>
      <h1 className="font-ginto-bold text-xl text-[24px] leading-[24px]">{t('method')}</h1>
      <div className="flex flex-col gap-2">
        {methods.map((m): JSX.Element => (
          <div
            key={m.id}
            onClick={(): void => handleMethodSelect(m.id)}
            className={`w-full cursor-pointer rounded-sb-md flex justify-between items-center px-6 py-4 h-[80px] ${
              selectedMethodState[0]?.method === m.id
                ? 'bg-sb-light-purple'
                : submitted && !method
                ? 'border border-sb-pink bg-sb-light-pink'
                : 'border-black border border-opacity-30'
            }`}>
            <img src={m.image.svg} alt={m.description} className="h-full" />
            <p>{m.description}</p>
          </div>
        ))}
        {submitted && !method && (
          <label className="text-sb-pink text-[15px] leading-[21px] ml-2.5 -mt-1">
            {t('methodRequired')}
          </label>
        )}
      </div>
      {issuers && (
        <>
          <h1 className="font-ginto-bold text-xl text-[24px] leading-[24px]">{t('issuer')}</h1>
          <div className="flex flex-col gap-1">
            <DropdownInput
              options={issuers.map(({ id, name, image: { svg } }): any => ({
                value: id,
                label: name,
                image: svg,
              }))}
              value={issuer}
              setValue={handleIssuerSelect}
              placeholder="Selecteer jouw bank"
              styles={{
                control: (): CSSObjectWithLabel => ({
                  height: '80px',
                  padding: '1rem 1.25rem',
                  borderColor: submitted && !issuer ? SB_PINK : '#0000004D',
                  backgroundColor: submitted && !issuer ? SB_LIGHT_PINK : 'white',
                }),
                valueContainer: (baseStyles): CSSObjectWithLabel => ({
                  ...baseStyles,
                  padding: '0',
                  flex: '1 1 0%',
                  width: '100%',
                  height: '100%',
                }),
              }}
              formatOptionLabel={OptionLabel}
            />
            {submitted && issuers.length && !issuer && (
              <label className="text-sb-pink text-[15px] leading-[21px] ml-2.5">
                {t('issuerRequired')}
              </label>
            )}
          </div>
        </>
      )}
    </>
  );
}

function OptionLabel({ label, image }: { label: string; image: string }): JSX.Element {
  return (
    <div className="flex items-center gap-2 pl-0.5 flex-1 w-full h-full my-auto">
      <img src={image} alt={label} className="h-12" />
      <p>{label}</p>
    </div>
  );
}

interface ConsentCheckboxesProps {
  consentState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  agreement: [string[], React.Dispatch<React.SetStateAction<string[]>>];
}

function ConsentCheckboxes({ consentState, agreement }: ConsentCheckboxesProps): JSX.Element {
  const { t } = useTranslation('translation', { keyPrefix: 'page.payment' });

  const [termsAndConditionsConsentGiven, setTermsAndConditionsConsentGiven] = consentState;
  const [newsletterConsentGiven, setNewsletterConsentGiven] = useState<boolean>(false);
  const [daikinNewsletterConsentGiven, setDaikinNewsletterConsentGiven] = useState<boolean>(false);
  const [noInfoConsentGiven, setNoInfoConsentGiven] = useState<boolean>(false);
  const [_agreements, setAgreements] = agreement;

  const handleAgreementGiven = (): void => {
    const agreementsGiven = [];
    if (termsAndConditionsConsentGiven) agreementsGiven.push('termsAndConditions');
    if (newsletterConsentGiven) agreementsGiven.push('newsletter');
    if (daikinNewsletterConsentGiven) agreementsGiven.push('daikin');
    if (noInfoConsentGiven) agreementsGiven.push('noInfo');
    setAgreements(agreementsGiven);
  };

  useEffect((): void => {
    handleAgreementGiven();
  }, [termsAndConditionsConsentGiven, newsletterConsentGiven, noInfoConsentGiven]);
  

  return (
    <>
      <div className="flex gap-4">
        <CheckboxInput className="mt-[3px]" checked={termsAndConditionsConsentGiven} setChecked={setTermsAndConditionsConsentGiven} />
        <p><a className="text-sm font-ginto-bold underline hover:text-gray-400" href='https://www.schaatsbaanrotterdam.nl/pagina/algemene-voorwaarden' target="_blank" rel="noopener noreferrer">{t('termsAndConditions')}</a></p>
      </div>
      <div className="flex gap-4">
        <CheckboxInput className="mt-[3px]" checked={newsletterConsentGiven} setChecked={setNewsletterConsentGiven} />
        <p className="text-sm">{t('newsletter')}</p>
      </div>
      <div className="flex gap-4">
        <CheckboxInput className="mt-[3px]" checked={daikinNewsletterConsentGiven} setChecked={setDaikinNewsletterConsentGiven} />
        <p className="text-sm">{t('daikinNewsletter')}</p>
      </div>
      <div className="flex gap-4">
        <CheckboxInput className="mt-[3px]" checked={noInfoConsentGiven} setChecked={setNoInfoConsentGiven} />
        <p className="text-sm">{t('noInfo')}</p>
      </div>
    </>
  );
}
