import React, { ImgHTMLAttributes } from 'react';

export type LogoProps = {
  variant?: 'black' | 'white';
  text?: boolean;
} & ImgHTMLAttributes<HTMLImageElement>;

export default function Logo({
  variant = 'white',
  text = false,
  ...props
}: LogoProps): JSX.Element {
  return (
    <a href='https://schaatsbaanrotterdam.nl'>
      <img
        {...props}
        src={`${process.env.PUBLIC_URL}/assets/logo/sbr_logo_${variant}${text ? '_text' : ''}.svg`}
        alt="logo"
      />
    </a>
  );
}
