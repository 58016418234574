import React, { ComponentProps, useEffect, useRef, useState } from 'react';
import { FaChevronUp, FaTrash } from 'react-icons/fa6';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { TbMinus, TbPlus } from 'react-icons/tb';
import IconButton from '../buttons/IconButton';
import Button from '../buttons/Button';
import useShoppingCart from '../../hooks/selectors/useShoppingCart';
import useOutsideClickEffect from '../../hooks/useOutsideClickEffect';
import DiscountInput from '../inputs/DiscountInput';
import DiscountList from './DiscountList';
import ExtendedLink from '../../routes/ExtendedLink';
import { SubscriptionPurchaseDto } from '../../types/subscription';
import useShop from '../../hooks/selectors/useShop';
import { cartService } from '../../services';
import MinimalisticNumberInput from '../inputs/MinimalisticNumberInput';
import useCrashHandler from '../../hooks/useCrashHandler';
import useMediaQuery from '../../hooks/useMediaQuery';
import ContentPanel from '../layouts/ContentPanel';

interface ShoppingCartProps {
  next?: string;
  allowNext?: boolean;
  buttonProps?: ComponentProps<typeof Button>;
}
export default function ShoppingCart(props: ShoppingCartProps): JSX.Element {
  const isDesktop = useMediaQuery()(1024);

  return isDesktop ? <DesktopShoppingCart {...props} /> : <MobileShoppingCart {...props} />;
}

export function DesktopShoppingCart(props: ShoppingCartProps): JSX.Element {
  return (
    <div className='flex flex-col gap-4'>
      <ContentPanel className="w-1/2 min-w-[440px]">
        <ItemsPanel />
        <OverviewPanel {...props} />
      </ContentPanel>
    </div>
  );
}

export function MobileShoppingCart(props: ShoppingCartProps): JSX.Element {
  const { purchases } = useShoppingCart();

  const [open, setOpen] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement>(null);
  useOutsideClickEffect((): void => setOpen(false), containerRef);
  useEffect((): void => {
    if (!purchases.length) setOpen(false);
  }, [purchases.length]);

  return (
    <div ref={containerRef}>
      <div className="h-[80px]" />
      <div className="fixed z-10 bg-white bottom-0 left-0 flex justify-center items-center shadow-[0px_-2px_10px_0px_#00000040] w-full">
        <OverviewPanel open={open} setOpen={setOpen} {...props} />
      </div>{' '}
      <div
        className={`fixed bottom-0 left-0 px-4 pt-5 pb-[120px] z-[9] w-full bg-white rounded-t-sb-lg transition-transform duration-300 ease-in-out transform flex justify-center ${
          open ? 'translate-y-0' : 'translate-y-full'
        }`}>
        <ItemsPanel />
      </div>
    </div>
  );
}
type MainPanelProps = {
  open?: boolean;
  setOpen?: (open: boolean) => void;
} & ShoppingCartProps;

function OverviewPanel({
  open,
  setOpen,
  next,
  buttonProps,
  allowNext,
}: MainPanelProps): JSX.Element {
  const {
    price: { totalPrice },
    purchases,
  } = useShoppingCart();

  return (
    <>
      <div className="flex items-center px-5 py-4 max-w-2xl justify-between w-full gap-2">
        <div className="flex gap-2 items-center transition-all duration-300">
          {setOpen && (
            <IconButton
              icon={FaChevronUp}
              onClick={(): void => setOpen(!open)}
              className={classNames('bg-sb-purple', {
                'rotate-180': open,
                'opacity-0 w-0': !purchases.length,
              })}
            />
          )}
          <div className="flex flex-col">
            <p className="font-ginto-bold text-[24px] leading-[24px]">
              €
              {!purchases.length
                ? '0.00'
                : (totalPrice.discountedPrice ?? totalPrice.price).toFixed(2) || '--,--'}
            </p>
          </div>
        </div>
        {!allowNext ? (
          <Button
            variant="pink"
            className={buttonProps ? buttonProps.className : 'invisible'}
            {...buttonProps}
            onClick={(event): void => {
              if (setOpen) {
                setOpen(false);
              }
              if (buttonProps?.onClick) {
                buttonProps?.onClick(event);
              }
            }}
          />
        ) : (
          next && (
            <ExtendedLink to={next}>
              <Button
                variant="pink"
                {...buttonProps}
                onClick={(event): void => {
                  if (setOpen) {
                    setOpen(false);
                  }
                  if (buttonProps?.onClick) {
                    buttonProps?.onClick(event);
                  }
                }}
              />
            </ExtendedLink>
          )
        )}
      </div>
    </>
  );
}

function ItemsPanel(): JSX.Element {
  const {
    price: { bookingFee },
    purchases,
  } = useShoppingCart();
  const { t } = useTranslation('translation', { keyPrefix: 'component.shoppingCart' });

  const groupedItems = purchases.reduce((acc, item): SubscriptionPurchaseDto[][] => {
    const existingGroup = acc.find((g): boolean =>
      _.isEqual(_.omit(g[0], 'purchaseId'), _.omit(item, 'purchaseId')),
    );

    if (existingGroup) existingGroup.push(item);
    else acc.push([item]);

    return acc;
  }, [] as SubscriptionPurchaseDto[][]);

  return (
    <>
      <div className="flex flex-col w-full max-w-2xl px-5 max-h-[calc(100vh-200px)] no-scrollbar overflow-scroll">
        <div className="flex flex-col gap-4 mt-4 divide-y divide-black divide-opacity-30">
          {groupedItems.map((items, i): JSX.Element => (
            <div className="flex flex-col gap-2 pt-4" key={i}>
              <ShoppingCartItemComponent items={items} />
            </div>
          ))}
          {!!purchases.length && (
            <div className="grid grid-cols-3 w-full pt-4">
              <p className="font-ginto-bold text-[18px] leading-[24px]">{t('bookingFee')} </p>
              <div />
              <p className="flex justify-end">
                €{(bookingFee.discountedPrice ?? bookingFee.price).toFixed(2)}
              </p>
            </div>
          )}
          <div className="flex flex-col gap-4 w-full">
            <DiscountInput className="pt-4" />
            <DiscountList />
          </div>
        </div>
      </div>
    </>
  );
}

function ShoppingCartItemComponent({
  items,
}: {
  items: SubscriptionPurchaseDto[];
}): JSX.Element | null {
  const crashHandler = useCrashHandler();
  const { subscriptionTypes } = useShop();

  const [countInput, setCountInput] = useState<number>(items.length);

  useEffect((): void => setCountInput(items.length), [items.length]);

  const handleQuantityChange = (count: number): void => {
    if (count >= 0)
      cartService.setItemWithQuantity(items[0], count).catch((err): void => {
        crashHandler(err);
        if (err.message !== 'PRICES_CHANGED') {
          setCountInput(items.length);
        }
      });
    else setCountInput(items.length);
  };

  const { subscriptionTypeId, discountedPrice, price } = items[0];
  const subscriptionType = subscriptionTypes.find((st): boolean => st.id === subscriptionTypeId);

  if (items.length === 0) return null;
  return (
    <div className="grid grid-cols-3 w-full gap-1">
      <p className="font-ginto-bold text-[18px] leading-[24px] w-full break-words truncate">{subscriptionType?.name}</p>
      <div className="flex gap-1.5 w-full justify-center ml-6">
        <IconButton
          onClick={(): void => handleQuantityChange(items.length - 1)}
          icon={TbMinus}
          className="w-fit"
        />
        <MinimalisticNumberInput
          value={countInput}
          onChange={(e): void => setCountInput(+e.target.value)}
          onBlur={(e): void => handleQuantityChange(+e.target.value)}
          className="w-10 invalid:border-red-500 invalid:bg-red-100 bg-sb-light-blue"
        />
        <IconButton
          onClick={(): void => handleQuantityChange(items.length + 1)}
          icon={TbPlus}
          className="w-fit"
        />
       <IconButton icon={FaTrash} onClick={(): void => handleQuantityChange(0)} className='border-none ml-2 z-10' iconClassName='text-gray-600 size-5'/>
      </div>
      <div className="flex gap-1 justify-end w-full">
        <p className={classNames({ 'line-through': discountedPrice !== undefined })}>
          €{(price * items.length).toFixed(2)}
        </p>
        {discountedPrice !== undefined && <p>€{(discountedPrice * items.length).toFixed(2)}</p>}
      </div>
    </div>
  );
}
